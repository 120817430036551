import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {

 getBankDetailsById,getBankDetailsList,deleteBankDetails,createBankDetails,updateBankDetails,

} from '../../services/BankService'


interface GetBankDetailsListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface BankDetailsState {
  bankDetailsList: any
  bankDetailsData: any
  createBankDetailsData: any
  updateBankDetailsData: any
 
}

const initialState: BankDetailsState = {
  bankDetailsList: null,
  bankDetailsData: null,
  createBankDetailsData: null,
  updateBankDetailsData: null,
  
}

export const createBankDetailsApi = createAsyncThunk(
  'BankDetails/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createBankDetails(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getBankDetailsListApi = createAsyncThunk(
  'BankDetailsList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetBankDetailsListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getBankDetailsList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getBankDetailsByIdApi = createAsyncThunk(
  'BankDetailsById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getBankDetailsById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateBankDetailsApi = createAsyncThunk(
  'updateBankDetails/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateBankDetails(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteBankDetailsApi = createAsyncThunk(
  'BankDetails/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteBankDetails(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

// createBankDetailsModule

const BankSlice = createSlice({
  name: 'Bank',
  initialState,
  reducers: {
    
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBankDetailsListApi.fulfilled, (state, action) => {
        state.bankDetailsList = action.payload
      })
      .addCase(getBankDetailsByIdApi.fulfilled, (state, action) => {
        state.bankDetailsData = action.payload
      })
      .addCase(createBankDetailsApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createBankDetailsData = action?.payload
      })
      .addCase(updateBankDetailsApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateBankDetailsData = action?.payload
      })
      
  },
})

export default BankSlice
