import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  getUserList,
  switchOrganization,
  switchOrganizationLocation,
} from '../../services/UserService'
import {getUserById} from '../../services/UserService'
import {createUser} from '../../services/UserService'
import {updateUser} from '../../services/UserService'
import {getAllOrganizations} from '../../services/UserService'
import {getAllUserList} from '../../services/UserService'

interface GetUserListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

interface UserState {
  UserList: any
  userData: any
  createUserData: any
  updateUserData: any
  AllOrganizationsData: any
  AllUserListData: any
  switchOrganizationId: any
}

const initialState: UserState = {
  UserList: null,
  userData: null,
  createUserData: null,
  updateUserData: null,
  AllOrganizationsData: null,
  AllUserListData: null,
  switchOrganizationId: null,
}

export const createUserApi = createAsyncThunk(
  'create-workflow/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createUser(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getUserListApi = createAsyncThunk(
  'UserList/api/get',
  async (
    {pageNumber, pageSize, filterkey }: GetUserListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getUserList(pageNumber, pageSize, filterkey)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getUserByIdApi = createAsyncThunk(
  'UserById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getUserById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateUserApi = createAsyncThunk(
  'updateUser/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateUser(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getAllOrganizationsApi = createAsyncThunk(
  'Organizations/api/get',
  async (_, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getAllOrganizations()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
export const getAllUserListApi = createAsyncThunk(
  'AllUserList/api/get',
  async (_, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getAllUserList()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const switchOrganizationById = createAsyncThunk(
  'switchOrganization/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await switchOrganization(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const switchOrganizationLocationById = createAsyncThunk(
  'switchOrganizationLocation/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await switchOrganizationLocation(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getUserListApi.fulfilled, (state, action) => {
        state.UserList = action.payload
      })
      .addCase(getUserByIdApi.fulfilled, (state, action) => {
        state.userData = action.payload
      })
      .addCase(createUserApi.fulfilled, (state, action) => {
        state.createUserData = action?.payload
      })
      .addCase(updateUserApi.fulfilled, (state, action) => {
        state.updateUserData = action?.payload
      })
      .addCase(getAllOrganizationsApi.fulfilled, (state, action) => {
        state.AllOrganizationsData = action.payload
      })
      .addCase(getAllUserListApi.fulfilled, (state, action) => {
        state.AllUserListData = action.payload
      })
      .addCase(switchOrganizationById.fulfilled, (state, action) => {
        state.switchOrganizationId = action?.payload
      })
  },
})
export default UserSlice
