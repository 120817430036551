import instance from './instance'

export const createWorkflow = async (payload: any) => {
  return await instance.post('workflows', payload).then((response: any) => {
    if (response) {
      return response.data
    }
  }).catch((err: any) => {
    if (err.response) {
      return err.response.data
    }
  }
  )
}

export const getWorkflowList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(`workflows?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

export const updateWorkflow = async (id: any, payload: any) => {
  return await instance.post(`workflows/${id}`, payload).then((response: any) => {
    if (response) {
      return response.data
    }
  }).catch((err: any) => {
    if (err.response) {
      return err.response.data
    }
  }
  )
}

export const deleteWorkflow = (id: any) => instance.delete(`workflows/${id}`)

export const getWorkflowById = (id: number) => instance.get(`workflows/${id}`)

// --------------------- Workflow Status ---------------------

export const saveWorkflowStatus = (id: any, payload: any) =>
  instance.post(`/saveworkflowstatuses/${id}`, payload)

export const getWorkflowStatus = (id: any) => instance.get(`getworkflowstatuses/${id}`)

// --------------------- Workflow State ---------------------

export const saveWorkflowState = (id: any, payload: any) =>
  instance.post(`workflows/states/${id}`, payload)

export const getWorkflowState = (id: any) => instance.get(`workflows/states/${id}`)

export const deleteWorkflowState = (id: any) => instance.delete(`workflows/states/${id}`)

// --------------------- Workflow Action ---------------------

export const createWorkflowAction = (payload: any) => instance.post('workflowactions', payload)

export const getWorkflowActionList = (id: any) => instance.get(`workflowactions/${id}`)

export const getWorkflowTransitionRulesList = (id: any) => instance.get(`workflows/transitionrules/${id}`)

export const savetransitionrules = (id: any, payload: any) =>
  instance.post(`workflows/updatetransitionrules/${id}`, payload)
// export const updateWorkflowAction = (id: any, payload: any) =>
//   instance.post(`workflowactions/${id}`, payload)

// export const deleteWorkflowAction = (id: any) => instance.delete(`workflowactions/${id}`)

// export const getWorkflowActionById = (id: number) => instance.get(`workflowactions/${id}`)

export const getModulesList = () => instance.get(`modules`)
