import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  createWorkflow,
  deleteWorkflow,
  getWorkflowById,
  getWorkflowList,
  updateWorkflow,
  saveWorkflowState,
  getWorkflowState,
  deleteWorkflowState,
  createWorkflowAction,
  getWorkflowActionList,
  // deleteWorkflowAction,
  getWorkflowStatus,
  saveWorkflowStatus,
  getWorkflowTransitionRulesList,
  savetransitionrules,
  getModulesList,
} from '../../services/WorkflowService'

interface GetWorkflowListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

interface WorkflowState {
  workflowList: any
  workflowData: any
  createWorkflowData: any
  updateWorkflowData: any
  workflowStatusList: any
  saveWorkflowStateData: any
  workflowStateList: any
  createWorkflowActionData: any
  workflowActionList: any
  saveWorkflowStatusData: any
  workflowStatesData: any
  workflowTransitionRulesList: any
  WorkflowNewTransitionRules: any
  saveWorkflowTransitionRulesData: any
  modulesList: any
}

const initialState: WorkflowState = {
  workflowList: null,
  workflowData: null,
  createWorkflowData: null,
  updateWorkflowData: null,
  workflowStatusList: null,
  saveWorkflowStateData: null,
  saveWorkflowStatusData: null,
  workflowStateList: null,
  createWorkflowActionData: null,
  workflowActionList: null,
  workflowTransitionRulesList: null,
  saveWorkflowTransitionRulesData: null,
  modulesList: null,
  workflowStatesData: [],
  WorkflowNewTransitionRules: [],
}

export const createWorkflowApi = createAsyncThunk(
  'create-Workflow/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createWorkflow(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getWorkflowListApi = createAsyncThunk(
  'WorkflowList/api/get',
  async (
    {pageNumber, pageSize, filterkey}: GetWorkflowListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getWorkflowList(pageNumber, pageSize, filterkey)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getWorkflowByIdApi = createAsyncThunk(
  'WorkflowById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getWorkflowById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateWorkflowApi = createAsyncThunk(
  'updateWorkflow/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateWorkflow(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteWorkflowApi = createAsyncThunk(
  'Workflow/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteWorkflow(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
// --------------------- Workflow Status ---------------------

export const saveWorkflowStatusApi = createAsyncThunk(
  'saveWorkflowStatus/api/post',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await saveWorkflowStatus(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getWorkflowStatusApi = createAsyncThunk(
  'WorkflowStatus/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getWorkflowStatus(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
// --------------------- Workflow State ----------------------

export const saveWorkflowStateApi = createAsyncThunk(
  'saveWorkflowState/api/post',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await saveWorkflowState(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getWorkflowStateApi = createAsyncThunk(
  'getWorkflowState/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getWorkflowState(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteWorkflowStateApi = createAsyncThunk(
  'Workflow/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteWorkflowState(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const workflowStates = createAsyncThunk(
  'workflowStates',
  async (statesData: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      console.log(statesData)
      const result = statesData
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

// --------------------- Workflow Action ---------------------

export const createWorkflowActionApi: any = createAsyncThunk(
  'createWorkflowAction/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createWorkflowAction(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getWorkflowActionListApi = createAsyncThunk(
  'getWorkflowActionList/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getWorkflowActionList(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
export const getWorkflowTransitionRulesListApi = createAsyncThunk(
  'getWorkflowTransitionRulesList/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getWorkflowTransitionRulesList(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const savetransitionrulesApi = createAsyncThunk(
  'savetransitionrules/api/post',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await savetransitionrules(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const workflowNewTransitionRules = createAsyncThunk(
  'workflowNewTransitionRules',
  async (transitionRulesData: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      console.log(transitionRulesData)
      const result = transitionRulesData
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

// -----------------------------------------------------------

export const getModulesListApi = createAsyncThunk(
  'ModulesList/api/get',
  async (_, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getModulesList()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

const WorkflowSlice = createSlice({
  name: 'Workflow',
  initialState,
  reducers: {
    updateData: (state, action) => {
      let data = state.workflowStatesData.map((item: any, index: number) => {
        if (index == action.payload.row.index) {
          return action.payload
        } else return item
      })

      console.log(data)
      state.workflowStatesData = data
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getWorkflowListApi.fulfilled, (state, action) => {
        state.workflowList = action.payload
      })
      .addCase(getWorkflowByIdApi.fulfilled, (state, action) => {
        state.workflowData = action.payload
      })
      .addCase(createWorkflowApi.fulfilled, (state, action) => {
        state.createWorkflowData = action?.payload
      })
      .addCase(updateWorkflowApi.fulfilled, (state, action) => {
        state.updateWorkflowData = action?.payload
      })
      // --------------------- Workflow Status ---------------------

      .addCase(getWorkflowStatusApi.fulfilled, (state, action) => {
        state.workflowStatusList = action?.payload
      })
      .addCase(saveWorkflowStatusApi.fulfilled, (state, action) => {
        state.saveWorkflowStatusData = action?.payload
      })
      // --------------------- Workflow State ----------------------

      .addCase(getWorkflowStateApi.fulfilled, (state, action) => {
        state.workflowStateList = action.payload
      })
      .addCase(saveWorkflowStateApi.fulfilled, (state, action) => {
        state.saveWorkflowStateData = action?.payload
      })
      .addCase(workflowStates.fulfilled, (state, action) => {
        const data = action.payload
        const preData = state.workflowStatesData

        console.log(data)
        console.log(preData)

        state.workflowStatesData = preData ? [...preData, data] : [data]
      })
      // --------------------- Workflow Action ----------------------

      .addCase(getWorkflowActionListApi.fulfilled, (state, action) => {
        state.workflowActionList = action.payload
      })
      .addCase(createWorkflowActionApi.fulfilled, (state, action) => {
        state.createWorkflowActionData = action?.payload
      })
      .addCase(getWorkflowTransitionRulesListApi.fulfilled, (state, action) => {
        state.workflowTransitionRulesList = action?.payload
      })
      .addCase(savetransitionrulesApi.fulfilled, (state, action) => {
        state.saveWorkflowTransitionRulesData = action?.payload
      })

      .addCase(workflowNewTransitionRules.fulfilled, (state, action) => {
        const data = action.payload
        const preData = state.WorkflowNewTransitionRules

        console.log(data)
        console.log(preData)

        state.WorkflowNewTransitionRules = preData ? [...preData, data] : [data]
      })
      .addCase(getModulesListApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.modulesList = action?.payload
      })
  },
})
export const {updateData} = WorkflowSlice.actions
export default WorkflowSlice
