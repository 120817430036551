import instance from './instance'

export const createRole = (payload: any) => instance.post('roles', payload)

export const getRoleList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(`roles?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

export const updateRole = (id: any, payload: any) => instance.post(`roles/${id}`, payload)

export const deleteRole = (id: any) => instance.delete(`roles/${id}`)

export const getRoleById = (id: number) => instance.get(`roles/${id}`)

export const getAllGroups = () => instance.get(`groups`)

export const getPermissionList = ({is_dropdown}:{is_dropdown:any}) => instance.get(`permissions?is_dropdown=${is_dropdown}`)

export const manageGroupsForRole = (id: any, payload: any) =>
  instance.post(`managegroupsforrole/${id}`, payload)

export const assignPermissionsToRole = (id: any, payload: any) =>
  instance.post(`assignpermissionstorole/${id}`, payload)

export const getAllRoleList = () => instance.get(`roles`)
