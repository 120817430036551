import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  createModulePermissions,
  deleteModule,
  deleteModulePermissions,
  getModuleList,
  getModulePermissionsByModuleId,
  getModulePermissionsList,
} from '../../services/ModuleService'
import {getModuleById} from '../../services/ModuleService'
import {createModule} from '../../services/ModuleService'
import {updateModule} from '../../services/ModuleService'
import {getAllModuleList} from '../../services/ModuleService'

interface GetModuleListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface ModuleState {
  ModuleList: any
  ModuleData: any
  createModuleData: any
  updateModuleData: any
  AllModuleListData: any
  createModulePermission: any
  deleteModulePermission: any
  listModulePermission: any
  jsonData: any
}

const initialState: ModuleState = {
  ModuleList: null,
  ModuleData: null,
  createModuleData: null,
  updateModuleData: null,
  AllModuleListData: null,
  jsonData: [],
  createModulePermission: null,
  deleteModulePermission: null,
  listModulePermission: null,
}

export const createModuleApi = createAsyncThunk(
  'create-workflow/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createModule(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getModuleListApi = createAsyncThunk(
  'ModuleList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetModuleListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getModuleList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getModuleByIdApi = createAsyncThunk(
  'ModuleById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getModuleById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateModuleApi = createAsyncThunk(
  'updateModule/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateModule(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteModuleApi = createAsyncThunk(
  'updateModule/api/put',

  async ({id}: {id: any}, {rejectWithValue}) => {
    try {
      console.log('run')

      const result = await deleteModule(id)
      return result
    } catch (error) {
      console.log('error')
      return rejectWithValue({error})
    }
  }
)

export const getAllModuleListApi = createAsyncThunk(
  'AllModuleList/api/get',
  async (_, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getAllModuleList()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const createModulePermissionApi = createAsyncThunk(
  'modulePermission/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createModulePermissions(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getModulePermissionListApi = createAsyncThunk(
  'ModulePermissionList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetModuleListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getModulePermissionsList(pageNumber, pageSize, filterkey, isDropdown)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getModulePermissionByModuleIdApi = createAsyncThunk(
  'ModulePermissionById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getModulePermissionsByModuleId(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteModulePermissionApi = createAsyncThunk(
  'updateModulePermission/api/delete',

  async (id: any, {rejectWithValue}) => {
    try {
      console.log('run', id)

      const result = await deleteModulePermissions(id)
      return result
    } catch (error) {
      console.log('error')
      return rejectWithValue({error})
    }
  }
)

const ModuleSlice = createSlice({
  name: 'Module',
  initialState,
  reducers: {
    setJsonList: (state, action) => {
      state.jsonData = action.payload
      console.log(state.jsonData, action.payload)
    },
    addOnJsonList: (state, action) => {
      state.jsonData = [...state.jsonData, action.payload]
    },
    deleteOnJsonList: (state, action) => {
      console.log(action, state.jsonData.splice(action.payload, 1))
      // state.jsonData = state.jsonData.splice(action,1)
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getModuleListApi.fulfilled, (state, action) => {
        state.ModuleList = action.payload
      })
      .addCase(getModuleByIdApi.fulfilled, (state, action) => {
        state.ModuleData = action.payload
      })
      .addCase(createModuleApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createModuleData = action?.payload
      })
      .addCase(updateModuleApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateModuleData = action?.payload
      })
      .addCase(getAllModuleListApi.fulfilled, (state, action) => {
        state.AllModuleListData = action.payload
      })
      .addCase(createModulePermissionApi.fulfilled, (state, action) => {
        state.createModulePermission = action.payload
      })
      .addCase(deleteModulePermissionApi.fulfilled, (state, action) => {
        state.deleteModulePermission = action.payload
      })
      .addCase(getModulePermissionByModuleIdApi.fulfilled, (state, action) => {
        state.listModulePermission = action.payload
      })
  },
})

export const {setJsonList, addOnJsonList, deleteOnJsonList} = ModuleSlice.actions
export default ModuleSlice
