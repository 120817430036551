import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  ModuleAdd,
  createOrganizationModule,
  createOrganizationSetting,
  deleteOrganizationModule,
  getOrganizationList,
  getOrganizationModuleList,
  getOrganizationSettingById,
  updateOrganizationModule,
  updateOrganizationSetting,
} from '../../services/OrganizationService'
import {
  getOrganizationById,
  deleteOrganization,
  createOrganization,
  updateOrganization,
} from '../../services/OrganizationService'

interface GetOrganizationListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface OrganizationState {
  OrganizationList: any
  organizationData: any
  createOrganizationData: any
  updateOrganizationData: any
  organizationSettingData: any
  organizationSettingUpdate: any
  moduleAdded: any
  moduleUpdated: any
  moduleListbyOrganization: any
  loading: {
    organizationList: boolean
    organizationData: boolean
    createOrganization: boolean
    updateOrganization: boolean
    moduleAdded: boolean
    moduleUpdated: boolean
    moduleListByOrganization: boolean
    organizationSetting: boolean
    updateOrganizationSetting: boolean
  }
}

const initialState: OrganizationState = {
  OrganizationList: null,
  organizationData: null,
  createOrganizationData: null,
  updateOrganizationData: null,
  organizationSettingData: null,
  moduleAdded: null,
  moduleUpdated: null,
  moduleListbyOrganization: null,
  organizationSettingUpdate: null,
  loading: {
    organizationList: false,
    organizationData: false,
    createOrganization: false,
    updateOrganization: false,
    moduleAdded: false,
    moduleUpdated: false,
    moduleListByOrganization: false,
    organizationSetting: false,
    updateOrganizationSetting: false,
  },
}

export const createOrganizationApi = createAsyncThunk(
  'create-workflow/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createOrganization(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getOrganizationListApi = createAsyncThunk(
  'OrganizationList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetOrganizationListParams,
    {rejectWithValue}
  ) => {
    try {
      const result = await getOrganizationList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getOrganizationByIdApi = createAsyncThunk(
  'OrganizationById/api/get',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await getOrganizationById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateOrganizationApi = createAsyncThunk(
  'updateOrganization/api/put',
  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateOrganization(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteOrganizationApi = createAsyncThunk(
  'Organization/api/delete',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await deleteOrganization(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const AddedOrganizationModuleApi = createAsyncThunk(
  'OrganizationModule/api/create',
  async (payload: ModuleAdd, {rejectWithValue}) => {
    try {
      const result = await createOrganizationModule(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const UpdateOrganizationModuleApi = createAsyncThunk(
  'OrganizationModule/api/update',
  async ({id, payload}: {id: string; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateOrganizationModule(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const GetmoduleListbyOrganizationId = createAsyncThunk(
  'OrganizationModule/api/list',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await getOrganizationModuleList(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const GetOrganizationSettingByIdApi = createAsyncThunk(
  'OrganizationSetting/api/data',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await getOrganizationSettingById(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const createOrganizationSettingApi = createAsyncThunk(
  'OrganizationSetting/api/create',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createOrganizationSetting(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const UpdateOrganizationSettingByIdApi = createAsyncThunk(
  'OrganizationSetting/api/update',
  async ({id, payload}: {id: string; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateOrganizationSetting(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const DeleteModuleFromOrganization = createAsyncThunk(
  'OrganizationModule/api/delete',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await deleteOrganizationModule(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

const OrganizationSlice = createSlice({
  name: 'Organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getOrganizationListApi
      .addCase(getOrganizationListApi.pending, (state) => {
        state.loading.organizationList = true
      })
      .addCase(getOrganizationListApi.fulfilled, (state, action) => {
        state.OrganizationList = action.payload
        state.loading.organizationList = false
      })
      .addCase(getOrganizationListApi.rejected, (state) => {
        state.loading.organizationList = false
      })

      // getOrganizationByIdApi
      .addCase(getOrganizationByIdApi.pending, (state) => {
        state.loading.organizationData = true
      })
      .addCase(getOrganizationByIdApi.fulfilled, (state, action) => {
        state.organizationData = action.payload
        state.loading.organizationData = false
      })
      .addCase(getOrganizationByIdApi.rejected, (state) => {
        state.loading.organizationData = false
      })

      // createOrganizationApi
      .addCase(createOrganizationApi.pending, (state) => {
        state.loading.createOrganization = true
      })
      .addCase(createOrganizationApi.fulfilled, (state, action) => {
        state.createOrganizationData = action.payload
        state.loading.createOrganization = false
      })
      .addCase(createOrganizationApi.rejected, (state) => {
        state.loading.createOrganization = false
      })

      // updateOrganizationApi
      .addCase(updateOrganizationApi.pending, (state) => {
        state.loading.updateOrganization = true
      })
      .addCase(updateOrganizationApi.fulfilled, (state, action) => {
        state.updateOrganizationData = action.payload
        state.loading.updateOrganization = false
      })
      .addCase(updateOrganizationApi.rejected, (state) => {
        state.loading.updateOrganization = false
      })

      // deleteOrganizationApi
      .addCase(deleteOrganizationApi.pending, (state) => {
        state.loading.updateOrganization = true
      })
      .addCase(deleteOrganizationApi.fulfilled, (state, action) => {
        state.updateOrganizationData = action.payload
        state.loading.updateOrganization = false
      })
      .addCase(deleteOrganizationApi.rejected, (state) => {
        state.loading.updateOrganization = false
      })

      // AddedOrganizationModuleApi
      .addCase(AddedOrganizationModuleApi.pending, (state) => {
        state.loading.moduleAdded = true
      })
      .addCase(AddedOrganizationModuleApi.fulfilled, (state, action) => {
        state.moduleAdded = action.payload
        state.loading.moduleAdded = false
      })
      .addCase(AddedOrganizationModuleApi.rejected, (state) => {
        state.loading.moduleAdded = false
      })

      // UpdateOrganizationModuleApi
      .addCase(UpdateOrganizationModuleApi.pending, (state) => {
        state.loading.moduleUpdated = true
      })
      .addCase(UpdateOrganizationModuleApi.fulfilled, (state, action) => {
        state.moduleUpdated = action.payload
        state.loading.moduleUpdated = false
      })
      .addCase(UpdateOrganizationModuleApi.rejected, (state) => {
        state.loading.moduleUpdated = false
      })

      // GetmoduleListbyOrganizationId
      .addCase(GetmoduleListbyOrganizationId.pending, (state) => {
        state.loading.moduleListByOrganization = true
      })
      .addCase(GetmoduleListbyOrganizationId.fulfilled, (state, action) => {
        state.moduleListbyOrganization = action.payload
        state.loading.moduleListByOrganization = false
      })
      .addCase(GetmoduleListbyOrganizationId.rejected, (state) => {
        state.loading.moduleListByOrganization = false
      })

      // GetOrganizationSettingByIdApi
      .addCase(GetOrganizationSettingByIdApi.pending, (state) => {
        state.loading.organizationSetting = true
      })
      .addCase(GetOrganizationSettingByIdApi.fulfilled, (state, action) => {
        state.organizationSettingData = action.payload
        state.loading.organizationSetting = false
      })
      .addCase(GetOrganizationSettingByIdApi.rejected, (state) => {
        state.loading.organizationSetting = false
      })

      // createOrganizationSettingApi
      .addCase(createOrganizationSettingApi.pending, (state) => {
        state.loading.createOrganization = true
      })
      .addCase(createOrganizationSettingApi.fulfilled, (state, action) => {
        state.organizationSettingData = action.payload
        state.loading.createOrganization = false
      })
      .addCase(createOrganizationSettingApi.rejected, (state) => {
        state.loading.createOrganization = false
      })

      // UpdateOrganizationSettingByIdApi
      .addCase(UpdateOrganizationSettingByIdApi.pending, (state) => {
        state.loading.updateOrganizationSetting = true
      })
      .addCase(UpdateOrganizationSettingByIdApi.fulfilled, (state, action) => {
        state.organizationSettingUpdate = action.payload
        state.loading.updateOrganizationSetting = false
      })
      .addCase(UpdateOrganizationSettingByIdApi.rejected, (state) => {
        state.loading.updateOrganizationSetting = false
      })

      // DeleteModuleFromOrganization
      .addCase(DeleteModuleFromOrganization.pending, (state) => {
        state.loading.moduleAdded = true
      })
      .addCase(DeleteModuleFromOrganization.fulfilled, (state, action) => {
        state.moduleAdded = action.payload
        state.loading.moduleAdded = false
      })
      .addCase(DeleteModuleFromOrganization.rejected, (state) => {
        state.loading.moduleAdded = false
      })
  },
})

export default OrganizationSlice
