import instance from './instance'

export const createModule = (payload: any) => instance.post('modules', payload)

export const getModuleList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`modules?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateModule = (id: any, payload: any) => instance.post(`modules/${id}`, payload)

export const getModuleById = (id: number) => instance.get(`modules/${id}`)

export const deleteModule = (id: any) => instance.delete(`modules/${id}`)

export const getAllModuleList = () => instance.get(`modules`)


// Module Permissions apis

export const createModulePermissions = (payload: any) => instance.post('modulepermission', payload)

export const getModulePermissionsList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`modulepermission?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const getModulePermissionsByModuleId = (id: number) => instance.get(`modulepermission/${id}`)

export const deleteModulePermissions = (id: any) => instance.delete(`modulepermission/${id}`)

