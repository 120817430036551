/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useEffect} from 'react'

import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  StatisticsWidget5,
} from '../../../_metronic/partials/widgets'

import {currentUser} from '../../services/AuthService'
import {useNavigate} from 'react-router-dom'
import {Charts} from '../../modules/widgets/components/Charts'
import {Feeds} from '../../modules/widgets/components/Feeds'
import {Lists} from '../../modules/widgets/components/Lists'
import {Mixed} from '../../modules/widgets/components/Mixed'
import {Statistics} from '../../modules/widgets/components/Statistics'
import {Tables} from '../../modules/widgets/components/Tables'
import WidgetsPage from '../../modules/widgets/WidgetsPage'

// import {Chart as } from "chart.js/auto/auto";
import {CategoryScale} from 'chart.js'
import {Bar, Doughnut, Line} from 'react-chartjs-2'
import {useDispatch} from 'react-redux'
import {getCurrentUser} from '../../redux/slices/AuthSlices'

// ChartClass.register(CategoryScale);

const DashboardPage: FC = () => {
  const jsonData = [
    {
      id: 2,
      svgIcon: 'people',
      color: 'card-primary',
      iconColor: 'white',
      title: '2',
      titleColor: 'black',
      description: 'Users',
      descriptionColor: 'black',
      onClick: () => navigate(`/setting/user`),
    },
    {
      id: 3,
      svgIcon: 'social-media',
      color: 'card-primary',
      iconColor: 'white',
      title: '1',
      titleColor: 'black',
      description: 'Custom Forms',
      descriptionColor: 'black',
      onClick: () => navigate(`/customized-form`),
    },
    {
      id: 4,
      svgIcon: 'fasten',
      color: 'card-primary',
      iconColor: 'white',
      title: '1',
      titleColor: 'black',
      description: 'Roles',
      descriptionColor: 'black',
      onClick: () => navigate(`/setting/role`),
    },
    {
      id: 5,
      svgIcon: 'share',
      color: 'card-primary',
      iconColor: 'white',
      title: '1',
      titleColor: 'black',
      description: 'Groups',
      descriptionColor: 'black',
      onClick: () => navigate(`/setting/group`),
    },
  ]

  let user_details_string: any = localStorage.getItem('currentUser')
  const user_details: any = JSON.parse(user_details_string)
  const name: any = user_details?.name

  const data = {
    labels: ['Fund', 'Profit', 'Loss'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Amount of transaction',
        data: [55, 23, 96],
        // you can set indiviual colors for each bar
        backgroundColor: ['rgba(93,70,201, 0.6)', '#fff', '#F0EEFD'],
        borderWidth: 1,
        width: 200,
        height: 200,
      },
    ],
  }

  const labels = ['js', 'js', 'js', 'js', 'js', 'js', 'js']
  const lineData = {
    labels: labels,
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  }

  const navigate = useNavigate()
  return (
    //     <>
    //   {/* begin::Row */}
    //   <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
    //     {/* begin::Col */}
    //     <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
    //       <CardsWidget20
    //         className='h-md-50 mb-5 mb-xl-10'
    //         description='Active Projects'
    //         color='#F1416C'
    //         img={toAbsoluteUrl('/media/patterns/vector-1.png')}
    //       />
    //       <CardsWidget7
    //         className='h-md-50 mb-5 mb-xl-10'
    //         description='Professionals'
    //         icon={false}
    //         stats={357}
    //         labelColor='dark'
    //         textColor='gray-300'
    //       />
    //     </div>
    //     {/* end::Col */}

    //     {/* begin::Col */}
    //     <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
    //       <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
    //       <ListsWidget26 className='h-lg-50' />
    //     </div>
    //     {/* end::Col */}

    //     {/* begin::Col */}
    //     <div className='col-xxl-6'>
    //       <EngageWidget10 className='h-md-100' />
    //     </div>
    //     {/* end::Col */}
    //   </div>
    //   {/* end::Row */}

    //   {/* begin::Row */}
    //   <div className='row gx-5 gx-xl-10'>
    //     {/* begin::Col */}
    //     <div className='col-xxl-6 mb-5 mb-xl-10'>
    //       {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
    //     </div>
    //     {/* end::Col */}

    //     {/* begin::Col */}
    //     <div className='col-xxl-6 mb-5 mb-xl-10'>
    //       {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
    //     </div>
    //     {/* end::Col */}
    //   </div>
    //   {/* end::Row */}

    //   {/* begin::Row */}
    //   <div className='row gy-5 gx-xl-8'>
    //     <div className='col-xxl-4'>
    //       <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
    //     </div>
    //     <div className='col-xl-8'>
    //       <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
    //     </div>
    //   </div>
    //   {/* end::Row */}

    //   {/* begin::Row */}
    //   <div className='row gy-5 g-xl-8'>
    //     <div className='col-xl-3'>
    //       <ListsWidget2 className='card-xl-stretch mb-xl-8' />
    //     </div>
    //     <div className='col-xl-3'>
    //       <ListsWidget6 className='card-xl-stretch mb-xl-8' />
    //     </div>
    //     <div className='col-xl-3'>
    //       <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
    //       {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
    //     </div>
    //   </div>
    //   {/* end::Row */}

    //   <div className='row g-5 gx-xxl-8'>
    //     <div className='col-xxl-4'>
    //       <MixedWidget8
    //         className='card-xxl-stretch mb-xl-3'
    //         chartColor='success'
    //         chartHeight='150px'
    //       />
    //     </div>
    //     <div className='col-xxl-8'>
    //       <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
    //     </div>
    //   </div>
    // </>

    <div>
      <div
        className='bg-card-primary mb-4 rounded px-4 py-4 d-flex justify-content-between'
        style={{width: '100%', height: '160px'}}
      >
        <div
          className='d-flex flex-column justify-content-center align-item-center text-white'
          style={{height: '150px'}}
        >
          <h1 className='text-black display-4 fw-normal'>Hello {name},</h1>
          <h6 className='text-black fw-bolder'>Welcome to AutomateBox!</h6>
        </div>
        <div style={{width: '180px', margin: '-10px 0'}}>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/features-logos/dashboard_icon1.png')}
            className='w-100 h-140px app-sidebar-logo-default theme-light-show'
          />
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        {jsonData.map((item: any) => (
          <div key={item.id} className='col-xl-3' onClick={item.onClick}>
            <StatisticsWidget5
              className={`card-xl-stretch mb-xl-8 bg-${item.color}`}
              svgIcon={item.svgIcon}
              color={item.color}
              iconColor={item.iconColor}
              title={item.title}
              titleColor={item.titleColor}
              description={item.description}
              descriptionColor={item.descriptionColor}
            />
          </div>
        ))}
      </div>

      <div className='row g-5 g-xl-10'>
        <div className='col-md-6'>
          <div className='card p-5'>
            <h2 style={{textAlign: 'center'}}>Transaction Report</h2>
            <Bar
              data={data}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: 'Users Gained between 2016-2020',
                  },
                },
              }}
            />
          </div>
        </div>
        <div className='col-md-6 '>
          <div className='card p-5'>
            <h2 style={{textAlign: 'center'}}>Expenses</h2>

            <div className=''>
              <Doughnut
                data={data}
                style={{width: '300px', height: '230px'}}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    title: {
                      display: true,
                      text: 'Users Gained between 2016-2020',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>

        <div className='col col-md-6 '>
          <div className='card p-5'>
            <h2 style={{textAlign: 'center'}}>Expenses</h2>

            <div className=''>
              <Line
                data={lineData}
                style={{width: '300px', height: '200px'}}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    title: {
                      display: true,
                      text: 'Users Gained between 2016-2020',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>

        {/* <Charts /> */}
        {/* <Feeds/> */}
        {/* <Lists/> */}
        {/* <Mixed/> */}
        {/* <Statistics/> */}
        {/* <Tables/> */}
        {/* <WidgetsPage/> */}
      </div>
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
