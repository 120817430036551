import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  createLocationModule,
  deleteLocationModule,
  getLocationModuleList,
  getOrganizationLocationByOrganization,
  getOrganizationLocationList,
  updateLocationModule,
} from '../../services/OrganizationLocationService'
import {getOrganizationLocationById} from '../../services/OrganizationLocationService'
import {deleteOrganizationLocation} from '../../services/OrganizationLocationService'
import {createOrganizationLocation} from '../../services/OrganizationLocationService'
import {updateOrganizationLocation} from '../../services/OrganizationLocationService'

interface GetOrganizationLocationListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface OrganizationLocationState {
  OrganizationLocationList: any
  organizationLocationData: any
  createOrganizationLocationData: any
  updateOrganizationLocationData: any
  OrganizationWiseLocation: any
  moduleAdded: any
  moduleUpdate: any
  moduleListbyLocation: any
}

const initialState: OrganizationLocationState = {
  OrganizationLocationList: null,
  organizationLocationData: null,
  createOrganizationLocationData: null,
  updateOrganizationLocationData: null,
  OrganizationWiseLocation: null,
  moduleAdded: null,
  moduleUpdate: null,
  moduleListbyLocation: null,
}

export const createOrganizationLocationApi = createAsyncThunk(
  'create-workflow/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createOrganizationLocation(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getOrganizationLocationListApi = createAsyncThunk(
  'OrganizationLocationList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetOrganizationLocationListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getOrganizationLocationList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getOrganizationLocationByIdApi = createAsyncThunk(
  'OrganizationLocationById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getOrganizationLocationById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateOrganizationLocationApi = createAsyncThunk(
  'updateOrganizationLocation/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateOrganizationLocation(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteOrganizationLocationApi = createAsyncThunk(
  'OrganizationLocation/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteOrganizationLocation(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getOrganizationLocationByOrganizationApi = createAsyncThunk(
  'OrganizationLocationbyOrganization/api/list',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getOrganizationLocationByOrganization(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const AddedLocationModuleApi = createAsyncThunk(
  'locationModule/api/create',
  async (payload: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await createLocationModule(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const UpdateLocationModuleApi = createAsyncThunk(
  'locationModule/api/update',
  async ({id, payload}: {id: string; payload: any}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await updateLocationModule(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const GetmoduleListbyLocationId = createAsyncThunk(
  'LocationModule/api/list',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getLocationModuleList(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const DeleteModuleFromLocation = createAsyncThunk(
  'LocationModule/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteLocationModule(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

const OrganizationLocationSlice = createSlice({
  name: 'OrganizationLocation',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationLocationListApi.fulfilled, (state, action) => {
        state.OrganizationLocationList = action.payload
      })
      .addCase(getOrganizationLocationByIdApi.fulfilled, (state, action) => {
        state.organizationLocationData = action.payload
      })
      .addCase(createOrganizationLocationApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createOrganizationLocationData = action?.payload
      })
      .addCase(updateOrganizationLocationApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateOrganizationLocationData = action?.payload
      })
      .addCase(getOrganizationLocationByOrganizationApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.OrganizationWiseLocation = action?.payload
      })
      .addCase(AddedLocationModuleApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.moduleAdded = action?.payload
      })
      .addCase(GetmoduleListbyLocationId.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.moduleListbyLocation = action?.payload
      })
      .addCase(UpdateLocationModuleApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.moduleUpdate = action?.payload
      })
  },
})
export default OrganizationLocationSlice
