import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getAllUsers, getGroupList} from '../../services/GroupService'
import {getGroupById} from '../../services/GroupService'
import {deleteGroup} from '../../services/GroupService'
import {createGroup} from '../../services/GroupService'
import {updateGroup} from '../../services/GroupService'
import {assignRolesToGroup} from '../../services/GroupService'
import {manageUsersforGroup} from '../../services/GroupService'
import {getAllRoles} from '../../services/GroupService'

interface GetGroupListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

interface GroupState {
  groupList: any
  groupData: any
  createGroupData: any
  updateGroupData: any
  AllUsersData: any
  manageUsersforGroupData: any
  assignRolesToGroupData: any
  AllRolesData: any
}

const initialState: GroupState = {
  groupList: null,
  groupData: null,
  createGroupData: null,
  updateGroupData: null,
  manageUsersforGroupData: null,
  assignRolesToGroupData: null,
  AllUsersData: null,
  AllRolesData: null,
}

export const createGroupApi = createAsyncThunk(
  'createGroup/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createGroup(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
export const manageUsersforGroupApi = createAsyncThunk(
  'manageUsersforGroup/api/post',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      console.log(id)
      const result = await manageUsersforGroup(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getGroupListApi = createAsyncThunk(
  'GroupList/api/get',
  async (
    {pageNumber, pageSize, filterkey}: GetGroupListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getGroupList(pageNumber, pageSize, filterkey)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getGroupByIdApi = createAsyncThunk(
  'GroupById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getGroupById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateGroupApi = createAsyncThunk(
  'updateGroup/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateGroup(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
export const assignRolesToGroupApi = createAsyncThunk(
  'assignRolesToGroup/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await assignRolesToGroup(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteGroupApi = createAsyncThunk(
  'Group/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteGroup(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getAllUsersApi = createAsyncThunk(
  'Users/api/get',
  async (_, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getAllUsers()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
export const getAllRoleApi = createAsyncThunk(
  'Role/api/get',
  async (_, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getAllRoles()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

const GroupSlice = createSlice({
  name: 'Group',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getGroupListApi.fulfilled, (state, action) => {
        state.groupList = action.payload
      })
      .addCase(getGroupByIdApi.fulfilled, (state, action) => {
        state.groupData = action.payload
      })
      .addCase(createGroupApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createGroupData = action?.payload
      })
      .addCase(updateGroupApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateGroupData = action?.payload
      })
      .addCase(getAllUsersApi.fulfilled, (state, action) => {
        state.AllUsersData = action.payload
      })
      .addCase(getAllRoleApi.fulfilled, (state, action) => {
        state.AllRolesData = action.payload
      })
      .addCase(manageUsersforGroupApi.fulfilled, (state, action) => {
        state.manageUsersforGroupData = action.payload
      })
      .addCase(assignRolesToGroupApi.fulfilled, (state, action) => {
        state.assignRolesToGroupData = action.payload
      })
  },
})
export default GroupSlice
