import instance from './instance'

export const createSystemCode =async (payload: any) => {
  return await instance.post('systemcodes', payload).then((response: any) => {
    if (response) {
      return response.data
    }
  }).catch((err: any) => { 
    if (err.response) {
      return err.response.data
    }
  }
  )
}

export const getSystemCodeList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(`systemcodes?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

export const updateSystemCode = async(id: any, payload: any) =>{
 return await instance.post(`systemcodes/${id}`, payload).then((response: any) => {
    if (response) {
      return response.data
    }
  }).catch((err: any) => { 
    if (err.response) {
      return err.response.data
    }
  }
  )
}

export const deleteSystemCode = (id: any) =>
  instance.delete(`systemcodes/${id}`)

export const getSystemCodeById = (id: number) => instance.get(`systemcodes/${id}`)

export const getSystemCodeByKeyword = (keyword: number) => instance.get(`systemcodesbykeyword/${keyword}`)

