import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getSystemCodeList} from '../../services/SystemCodeService'
import {getSystemCodeById} from '../../services/SystemCodeService'
import {deleteSystemCode} from '../../services/SystemCodeService'
import {createSystemCode} from '../../services/SystemCodeService'
import {updateSystemCode} from '../../services/SystemCodeService'
import {getSystemCodeByKeyword} from '../../services/SystemCodeService'

interface GetSystemCodeListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

interface SystemCodeState {
  systemCodeList: any
  systemCodeData: any
  systemCodeDataByKeyword: any
  createSystemCodeData: any
  updateSystemCodeData: any
}

const initialState: SystemCodeState = {
  systemCodeList: null,
  systemCodeData: null,
  systemCodeDataByKeyword: null,
  createSystemCodeData: null,
  updateSystemCodeData: null,
}

export const createSystemCodeApi = createAsyncThunk(
  'create-workflow/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createSystemCode(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getSystemCodeListApi = createAsyncThunk(
  'SystemCodeList/api/get',
  async (
    {pageNumber, pageSize, filterkey}: GetSystemCodeListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getSystemCodeList(pageNumber, pageSize, filterkey)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getSystemCodeByIdApi = createAsyncThunk(
  'SystemCodeById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getSystemCodeById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateSystemCodeApi = createAsyncThunk(
  'updateSystemCode/api/put',
  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateSystemCode(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteSystemCodeApi = createAsyncThunk(
  'SystemCode/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteSystemCode(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getSystemCodeByKeywordApi = createAsyncThunk(
  'SystemCodeByKeyword/api/get',
  async (keyword: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getSystemCodeByKeyword(keyword)
      // console.log(result)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

const SystemCodeSlice = createSlice({
  name: 'SystemCode',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSystemCodeListApi.fulfilled, (state, action) => {
        state.systemCodeList = action.payload
      })
      .addCase(getSystemCodeByIdApi.fulfilled, (state, action) => {
        state.systemCodeData = action.payload
      })
      .addCase(createSystemCodeApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createSystemCodeData = action?.payload
      })
      .addCase(updateSystemCodeApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateSystemCodeData = action?.payload
      })
      .addCase(getSystemCodeByKeywordApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.systemCodeDataByKeyword = action?.payload
      })
  },
})
export default SystemCodeSlice
