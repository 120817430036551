/* eslint-disable react/jsx-no-target-blank */
import { useNavigate } from 'react-router-dom'
import {KTIcon} from '../../../helpers'
import { RiBardLine } from "react-icons/ri";

const SidebarFooter = () => {
  const navigate = useNavigate();
  function navigateToAi(){
    navigate("/ask-ai")

  }
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <a
        // href={process.env.REACT_APP_PREVIEW_DOCS_URL}
        // target='_blank'
        className='btn btn-flex flex-center btn btn-light-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-dismiss-='click'
        title='Metronic Docs & Components'
        onClick={navigateToAi}
      >
        <span className='btn-label me-4'>Ask Ai</span>
        <RiBardLine/>
        {/* <KTIcon iconName='star' className='btn-icon fs-2 m-0 ' /> */}
      </a>
    </div>
  )
}

export {SidebarFooter}
