import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export const API_URL = process.env.REACT_APP_API_URL
export const BASE_URL = process.env.REACT_APP_BASE_URL

// export const BASE_URL = "https://hrm-qa-ss.developerbox.co.in/"
// const API_URL = "https://hrm-qa-ss.developerbox.co.in/api"


const instance: AxiosInstance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("loginToken");
    const cleanedToken = token ? token.replace(/"/g, '') : null;

    if (cleanedToken) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Authorization"] = `Bearer ${cleanedToken}`;
    }
    if (config.headers) {
      config.headers["Accept"] = config.headers["Accept"]
        ? config.headers["Accept"]
        : "application/json";
      config.headers["Content-Type"] = config.headers["application/json"]
      config.headers["X-Requested-With"] = "XMLHttpRequest";
      config.headers["Access-Control-Allow-Origin"]= "*";
    }

    return config;

  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;


